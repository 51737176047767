import React, { Component } from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const InfoPage = ({ data }) => (
  <Layout>
    <SEO
      title="Info and Fees"
      keywords={[
        `Caister Lodge`,
        `Luxury Retirement`,
        `Retirement Lodge`,
        `Durban Retirement`,
        `Berea Retirement`,
        `Care Centre`,
        `Retirement Apartments`,
        `Affordable Retirement`,
        `All Included Retirement`,
        `Retirement Activities`,
        `Retirement Home`,
        `Blog`
      ]}
    />
 <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0"/>
    <div className="site-container blogs-page" id="Info">
      <div className="container">
        <div
          dangerouslySetInnerHTML={{
            __html: data.contentfulInfoFees.infoFees.childMarkdownRemark.html
          }}
        />

        </div>
    </div>
  </Layout>
);

export default InfoPage;

export const pageQuery = graphql`
  query InfoQuery {
    contentfulInfoFees {
      infoFees {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
